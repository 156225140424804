<template>
  <register :title='title' :systemTitle="systemTitle" :align='align' :options='options' :logoOptions='logoOptions' :goLogin="goLogin" :background='background'
    @onRegist="showRegist" @chengeImgVerify="chengeImgVerify" @sendPhoneMessage='sendPhoneMessage'></register>
</template>

<script>
import register from '../../components/Register'

export default {
  data() {
    return {
      // 标题,显示在注册表单上方
      systemTitle: '兑欢券管理系统',
      title: '注册',
      // 注册框位置,可选 left/center/right
      align: 'center',

      // 是否显示表单下面的前往登录链接
      goLogin: true,

      // 注册页背景,可以为图片,也可以传输颜色, 颜色必须以'#'开头
      background: {
        'background-color': '#F4F5F8',
        'background-image': 'url(' + require('../../components/Login/img/bg_login_img.jpg') + ')',
        'background-size': 'cover'
      },

      logoOptions: {
        left: '10px',
        logoType: 'img',
        title: 'YIMI蚁米',
        width: '180px',
        url: require('../../components/Layout/img/logo.png')
      },

      // 注册表单项详细配置,不传入则全部采用默认设置
      options: {

        // 账号栏配置,不传入则采用默认账号栏配置
        account: {
          mapping: 'account',
          // 账号栏label名,显示在输入框上方
          label: '账号',
          // 账号栏左侧图标,目前功能尚未实现
          icon: 'el-icon-user',
          // 账号输入框表单校验正则,作为注册操作以及第一次点击注册后的校验依据
          pattern: /^[a-zA-Z0-9_-]{6,16}$/,
          // 开启校验后,若校验未通过,则在输入框下方进行红字提示
          massage: '6到16位（字母，数字，下划线，减号）'
        },

        // 密码栏配置,不传入则采用默认密码栏设置
        password: {
          label: '密码',
          icon: 'el-icon-lock',
          pattern: /^[a-zA-Z0-9_-]{6,16}$/,
          massage: '6到16位（字母，数字，下划线，减号）'
        },

        // 确认密码栏配置,不传入则采用默认密码栏设置
        confirm: {
          label: '确认密码',
          icon: 'el-icon-lock',
          pattern: /^[a-zA-Z0-9_-]{6,16}$/,
          massage: '密码不合规范或两次密码不一致'
        },

        // 绑定手机,不传入则不显示
        phone: {
          label: '手机',
          icon: 'el-icon-mobile-phone',
          pattern: /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/,
          massage: '请输入正确的手机号码'
        },

        // 短信验证码配置,不传入则不显示短信验证码
        phoneVerify: {
          label: '短信验证码',
          icon: 'el-icon-mobile-phone',
          pattern: /\S/,
          massage: '短信验证码不能为空',
          // 重新发送短信倒计时
          countDownDefault: 50
        },

        // 图片验证码配置,不传入则不显示图片验证码
        imgVerify: {
          // 图片验证码路径
          label: '图片验证码',
          icon: 'el-icon-edit-outline',
          pattern: /\S/,
          massage: '图片验证码不能为空',
          // 图片验证码路径
          url: require('../../components/Login/img/imgVerify.png')
        }
      }
    }
  },
  components: {
    register
  },
  methods: {
    // 执行注册操作,并将回调的账号保存在本地
    showRegist(form) {
      console.log('执行注册操作')
      console.log({ form: form })
    },
    // 更换验证码回调方法,传入图片路径即可更换验证码
    chengeImgVerify() {
      console.log('更换验证码')
      this.options.imgVerify.url = 'https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=1114443007,4076857238&fm=26&gp=0.jpg'
    },
    // 发送手机验证码事件
    sendPhoneMessage() {
      console.log('发送验证码')
    }
  }
}
</script>

<style lang="scss">
</style>
