<template>
  <div class="login-container register" :style="backgroundStyle">

    <!-- 左侧logo -->
    <div class='logo-box' v-if="logoOptions" :style="{left: logoOptions.left, width:logoOptions.width}" >
      <template v-if="logoOptions.logoType=='img'">
        <img :src="logoOptions.url" :alt="logoOptions.title||''">
      </template>
      <template v-if="logoOptions.logoType=='text'">
        <span class="logo-title">{{logoOptions.title}}</span>
      </template>
    </div>

    <!-- 登录框 -->
    <div class="form-container" :class="align">
       <!-- 中部标题, 系统标题 -->
      <div class="login-form__title">
          {{systemTitle}}
      </div>
      <!-- 登录组件标题 -->
      <h3>{{title}}</h3>

      <!-- 登录表单 begin -->
      <!-- 账号 -->
      <div class="input-group">
        <div class='input-label' :class="{'input-title': closeLabelAnimation}">{{applyOptions.account.label}}</div>
        <input class="input-text" type="text" v-model="form.account">
        <label v-if="formStyle=='icon' && applyOptions.account.icon">
          <i class="input-icon" :class="applyOptions.account.icon"></i>
        </label>
        <transition name="fade">
          <span class="textMessage" v-show="applyOptions.account.showMessage">{{applyOptions.account.massage}}</span>
        </transition>
      </div>

      <!-- 密码 -->
      <div class="input-group">
        <div class='input-label' :class="{'input-title': closeLabelAnimation}">{{applyOptions.password.label}}</div>
        <input class="input-text" type="password" v-model="form.password">
        <label v-if="formStyle=='icon' && applyOptions.password.icon">
          <i class="input-icon" :class="applyOptions.password.icon"></i>
        </label>
        <transition name="fade">
          <span class="textMessage" v-show="applyOptions.password.showMessage">{{applyOptions.password.massage}}</span>
        </transition>
      </div>

      <!-- 确认密码 -->
      <div class="input-group">
        <div class='input-label' :class="{'input-title': closeLabelAnimation}">{{applyOptions.confirm.label}}</div>
        <input class="input-text" type="password" v-model="form.confirm">
        <label v-if="formStyle=='icon' && applyOptions.confirm.icon">
          <i class="input-icon" :class="applyOptions.confirm.icon"></i>
        </label>
        <transition name="fade">
          <span class="textMessage" v-show="applyOptions.confirm.showMessage">{{applyOptions.confirm.massage}}</span>
        </transition>
      </div>

      <!-- 手机号码 -->
      <div class="input-group" v-if="applyOptions.phone">
        <div class='input-label' :class="{'input-title': closeLabelAnimation}">{{applyOptions.phone.label}}</div>
        <input class="input-text" type="number" v-model="form.phone">
        <label v-if="formStyle=='icon' && applyOptions.phone.icon">
          <i class="input-icon" :class="applyOptions.phone.icon"></i>
        </label>
        <transition name="fade">
          <span class="textMessage" v-show="applyOptions.phone.showMessage">{{applyOptions.phone.massage}}</span>
        </transition>
      </div>

      <!-- 手机验证码 -->
      <div v-if="applyOptions.phoneVerify" class="input-group" style="border:none">
        <div class='input-label' :class="{'input-title': closeLabelAnimation}">{{applyOptions.phoneVerify.label}}</div>
        <input class="input-text verify-text" v-model='form.phoneVerify' type="text">
         <el-button class="verify-btn" :disabled="!(['发送验证码', '重新发送'].includes(phoneText))" @click='sendPhoneMessage(true)'>{{phoneText}}</el-button>
        <label v-if="formStyle=='icon' && applyOptions.phoneVerify.icon">
          <i class="input-icon" :class="applyOptions.phoneVerify.icon"></i>
        </label>
        <transition name="fade">
          <span class="textMessage" style="top: 0" v-show="applyOptions.phoneVerify.showMessage">{{applyOptions.phoneVerify.massage}}</span>
        </transition>
      </div>

      <!-- 图片验证码 -->
      <div v-if="applyOptions.imgVerify" class="input-group" style="border:none">
        <div class='input-label' :class="{'input-title': closeLabelAnimation}">{{applyOptions.imgVerify.label}}</div>
        <input class="input-text verify-text" type="text" v-model='form.imgVerify'>
        <img class="verify-btn" :src="applyOptions.imgVerify.url" @click="chengeImgVerify">
        <label v-if="formStyle=='icon' && applyOptions.imgVerify.icon">
          <i class="input-icon" :class="applyOptions.imgVerify.icon"></i>
        </label>
        <transition name="fade">
          <span class="textMessage" style="top: 0" v-show="applyOptions.imgVerify.showMessage">{{applyOptions.imgVerify.massage}}</span>
        </transition>
      </div>
      <!-- 登录表单 end -->

      <!-- 登录按钮,点击登录后开启校验 -->
      <el-button type="primary" class="login-btn" @click="beforeRegist" :loading="registing">注册</el-button>
      <div v-if="goLogin" class="register-guide">已有账号？<router-link :to="{name:'login'}"> 点击登录 </router-link></div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    systemTitle: {
      type: String,
      default: '管理系统'
    },
    // 组件标题,可传入任意字符串,建议六字符以内
    title: {
      type: String,
      default: '注册'
    },

    // 登录框位置,可选 left/center/right
    align: {
      type: String,
      default: 'center'
    },

    // 表单风格, default默认无风格/icon图标风格/label标签风格
    formStyle: {
      type: String,
      default: 'default'
    },

    // 底部信息栏,通常用于放置备案信息
    bottomText: {
      type: String
    },

    // 是否显示表单下面的前往登录链接
    goLogin: {
      type: Boolean,
      default: true
    },

    // 背景,可设置颜色或背景图片url,若传入颜色则需以'#'开头
    background: {
      type: Object,
      default: () => {
        return {}
      }
    },

    // 左上角logo
    logoOptions: {
      type: Object,
      default: () => {
        return {}
      }
    },

    // 登录表单配置项
    options: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      registing: false,
      closeLabelAnimation: true, // 是否关闭表单label动画,为true则动画关闭
      backgroundStyle: { // 背景环境配置
        'background-color': '#F4F5F8',
        'background-image': 'url(/img/bg_login_img.b54361b1.jpg)',
        'background-size': 'cover'
      },
      form: { // 表单值
        account: '',
        password: '',
        confirm: '',
        phone: '',
        phoneVerify: '',
        imgVerify: ''
      },
      phoneText: '发送验证码',
      countDown: 0, // 当前倒计时
      checked: false, // 是否开启校验表单,点击登录后此项为true
      checkResult: false, // 校验结果
      applyOptions: { // 表单项配置项
        account: {
          label: '账号',
          icon: 'el-icon-user',
          pattern: /^[a-zA-Z0-9_-]{4,16}$/,
          massage: '4到16位（字母，数字，下划线，减号）',
          showMessage: false
        },
        password: {
          label: '密码',
          icon: 'el-icon-lock',
          pattern: /^[a-zA-Z0-9_-]{4,16}$/,
          massage: '4到16位（字母，数字，下划线，减号）',
          showMessage: false
        },
        confirm: {
          label: '确认密码',
          icon: 'el-icon-lock',
          pattern: /^[a-zA-Z0-9_-]{4,16}$/,
          massage: '密码不合规范或两次密码不一致',
          showMessage: false
        },
        phone: {
          label: '手机',
          icon: 'el-icon-mobile-phone',
          pattern: /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/,
          massage: '请输入正确的手机号码',
          showMessage: false
        },
        phoneVerify: {
          label: '短信验证码',
          icon: 'el-icon-mobile-phone',
          pattern: /\S/,
          massage: '短信验证码不能为空',
          countDownDefault: 60,
          showMessage: false
        },
        imgVerify: {
          label: '图片验证码',
          icon: 'el-icon-edit-outline',
          pattern: /\S/,
          massage: '图片验证码不能为空',
          url: require('../../components/Login/img/imgVerify.png'),
          showMessage: false
        }
      }
    }
  },
  watch: {
    // 背景样式对象
    background: {
      deep: true,
      immediate: true,
      handler (value) {
        Object.assign(this.backgroundStyle, value)
      }
    },
    // 表单配置对象
    options: {
      deep: true,
      immediate: true,
      handler (value) {
        if (!value.phone) {
          this.$delete(this.applyOptions, 'phone')
        }
        if (!value.imgVerify) {
          this.$delete(this.applyOptions, 'imgVerify')
        }
        if (!value.phoneVerify) {
          this.$delete(this.applyOptions, 'phoneVerify')
        }
        for (var key in this.applyOptions) {
          Object.assign(this.applyOptions[key], value[key])
        }
      }
    },
    // 对填写表单项进行校验
    form: {
      handler: function(val, oldval) {
        this.checkedForm()
      },
      deep: true
    }
  },
  mounted() {
    // 是否关闭表单动画,若不关闭,则绑定label效果
    if (this.formStyle === 'label') {
      this.closeLabelAnimation = false
      document.querySelectorAll('.input-text').forEach(function(item, i) {
        item.onfocus = function(e) {
          this.previousElementSibling.className += ' input-title'
        }
        item.onblur = function(e) {
          if (this.value.trim() === '') {
            this.previousElementSibling.className = 'input-label'
          }
        }
      })
    } else if (this.formStyle === 'icon') {
      document.querySelectorAll('.input-text').forEach(function(item, i) {
        item.className += ' text-indent'
      })
    }

    this.initCountDown()
  },
  methods: {
    // 初始化倒计时
    initCountDown() {
      if (this.applyOptions.phoneVerify) {
        this.countDown = this.applyOptions.phoneVerify.countDownDefault || 60
      }
    },
    // 清除按钮的loading状态
    resetButton() {
      this.$set(this, 'logining', false)
    },
    // 点击图片验证码
    chengeImgVerify() {
      this.$emit('chengeImgVerify')
    },

    // 点击发送验证码按钮
    sendPhoneMessage(clickFlag) {
      var thisVue = this
      // 进行手机校验
      if (!this.checkedItem(Number(this.form.phone), /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/, null, function() {
        thisVue.$message.error('请填写正确的手机号码')
      })) {
        return
      }

      // 首次发送调起父页面方法
      if (clickFlag) {
        this.$emit('sendPhoneMessage')
        this.initCountDown()
      }
      thisVue.countDown--
      thisVue.phoneText = thisVue.countDown + 's'
      setTimeout(function() {
        if (thisVue.countDown === 1) {
          thisVue.phoneText = '重新发送'
          thisVue.countDown = thisVue.countDownDefault
          return
        }
        thisVue.sendPhoneMessage()
      }, 1000)
    },

    // 对填写表单项进行校验
    checkedForm() {
      var thisVue = this

      // 若校验未开启,则不进行检查
      if (!this.checked) {
        return
      }
      thisVue.checkResult = true
      for (var key in this.applyOptions) {
        this.checkedItem(this.form[key], this.applyOptions[key].pattern, function() {
          thisVue.applyOptions[key].showMessage = false
        }, function() {
          thisVue.applyOptions[key].showMessage = true
          thisVue.checkResult = false
        })
        if (key === 'confirm') {
          if (!(this.form.password === this.form.confirm)) {
            thisVue.applyOptions.confirm.showMessage = true
            thisVue.checkResult = false
          }
        }
      }
    },

    // 进行正则校验
    checkedItem(item, reg, successFun, errorFun) {
      try {
        if (reg.test(item)) {
          if (typeof (successFun) === 'function') {
            successFun()
          }
          return true
        } else {
          if (typeof (errorFun) === 'function') {
            errorFun()
          }
          return false
        }
      } catch (e) {
        console.error('参数传递错误,进行' + item + '的正则校验失败')
      }
    },

    // 登录按钮触发事件
    beforeRegist() {
      this.checked = true
      this.checkedForm()
      if (this.checkResult) {
        this.registing = true
        this.$emit('onRegist', this.form)
      }
    }
  }
}
</script>

<style lang="scss">

  .fade-enter-active, .fade-leave-active {
    transition: opacity .2s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .text-indent {
    text-indent: 25px
  }

  .login-container {
    width: 100%;
    min-height: 100%;
    position: relative;
    overflow: auto;
    background-size: 100% 100%;

    .center{
       left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }

    .left{
      left: 15%;
       top: 50%;
        transform: translate(-50%,-50%);
    }

    .right{
      right: 15%;
      top: 50%;
        transform: translate(-50%,-50%);
    }

    .logo-box {
      position: absolute;
      margin: 5px 15px;
      overflow: hidden;
      user-select: none;

      img {
        margin: 0 auto;
        display: block;
      }

      .logo-title {
        font-family: 'fangsong';
        font-size: $--font-size-extra-large;
        color: $--color-primary;
        text-shadow: 0px 0px 1px $--color-primary;
      }
    }

    .form-container {
      width: 360px;
      min-height: 420px;
      height: fit-content;
      background: $--color-white;
      box-shadow: 0px 11px 30px 2px rgba(8, 22, 56, 0.11);
      border-radius: 10px;
      position: absolute;

      h3{
        font-weight: 500;
        color:$--color-primary;
        text-align: center;
        margin: 30px 0 30px;
        letter-spacing: 2px;
      }

      .input-group {
        width: 66%;
        height: 45px;
        margin: 12px 17%;
        border-bottom: 1px solid $--border-color;
        position: relative;
        float: left;
        border-radius: 0px;
        color: $--border-color;

        .textMessage{
          font-size:  $--font-size-extra-small;
          color: $--color-danger;
          position: relative;
          top: 44px;
        }
      }
      .input-icon {
        color: $--border-color;
        position: absolute;
        font-size: $--font-size-large;
        top: 24px;
      }
      .input-label {
          position: absolute;
          font-size: $--font-size-medium;
          left: 2px;
          letter-spacing: 2px;
          line-height: 40px;
          height: 40px;
          transition: all 0.8s ease;
          color: $--color-text-primary;
          opacity: 0.45;
          top: 8px;
      }
      .input-title{
        letter-spacing: 0px;
        font-size: $--font-size-extra-small;
        top: 0px;
        z-index: 2;
        height: 10px;
        line-height: 10px;
        left: 0px;
      }
      .input-text {
        left: 0px;
        bottom: 0px;
        position: absolute;
        border: none;
        height: 26px;
        background: transparent;
        font-size: $--font-size-medium;
        color: $--color-text-primary;
        width: 100%;
        outline: none;
      }

      .verify-text{
        width: 130px;
        border-bottom: 1px solid $--border-color;
      }

      .input-text:focus + label>img {
        -webkit-transition: all 0.6s ease;
        -moz-transition: all 0.6s ease;
        -ms-transition: all 0.6s ease;
        -o-transition: all 0.6s ease;
        transition: all 0.6s ease;
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }

      .login-btn{
        width: 70%;
        margin: 20px 15%;
      }

      .verify-btn {
        margin: 3px 0px 0px 144px;
        height: 40px;
        width: 100px;
        margin: 9px 0px 0px 134px;
        height: 35px;
        width: 108px;
        font-size: $--font-size-extra-small;
      }

      .register-guide{
        font-size: $--font-size-small;
        font-family: PingFang SC;
        font-weight: 400;
        color: $--color-text-primary;
        line-height: 31px;
        text-align: center;
        border-top: 1px solid $--border-color-base;
        height: 50px;
        line-height: 50px;

        a {
          text-decoration: none;
          color:$--color-primary;
        }
      }
    }

  }

  .bottom-box {
    font-size: $--font-size-extra-small;
    color: $--color-text-regular;
    position: absolute;
    bottom: 5px;
    right: 10%;
  }

  /* 谷歌隐藏number计数器 */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
  }
  /* 火狐隐藏number计数器 */
  input{
      -moz-appearance:textfield;
  }

  input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-transition-delay: 999999s;
        -webkit-transition: color 999999s ease-out, background-color 999999s ease-out;
}
.login-form__title{
    width:max-content;
    color:$--color-primary;
    font-size: $--font-h2-size;
    position: absolute; left: 50%;
    transform: translateX(-50%);
    top: -16%;
    text-align: center;
  }
  @media screen and (max-width: 1024px) {
       .login-container{
        .center{
            left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
          }

          .left{
           left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
          }

          .right{
             left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
          }
       }

  }
   @media screen and (max-width: 768px) {
       .login-container{
        .center{
              left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
          }

          .left{
             left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
          }

          .right{
            left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
          }
       }

  }

  @media screen and (max-width: 640px) {
    .login-container{
      .form-container {
        width: 92%;
        min-height: 420px;
        height: fit-content;
        background: $--color-white;
        box-shadow: 0px 11px 30px 2px rgba(8, 22, 56, 0.11);
        border-radius: 10px;
        position: absolute;
        box-sizing: border-box;
        .input-group{
            margin: 10px 10%;
          }
        .login-btn{
            margin:20px 15%;
          }
        .input-group {
          width: 80%;
          height: 45px;
          margin: 15px 10%;
          border-bottom: 1px solid $--border-color;
          position: relative;
          float: left;
          border-radius: 0px;
          color:$--border-color;

          .textMessage{
            font-size: $--font-size-extra-small;
            color: $--color-danger;
            position: relative;
            top: 44px;
          }
        }
        .verify-text{
            width: 50%;
          }
        .verify-btn {
          margin: 9px 0px 0px 54%;
        }
       }

        h3{
        margin: 10px 0 10px;
        font-size: $--font-size-medium;
        }
      .center{
         left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
      }

      .left{
          left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
      }

      .right{
         left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
      }
    }
     .register{
      .login-form__title{
        width: 54%;
        position: fixed;
        right: 10px;
        left:unset;
        transform: translateX(0);
        top: 5px;
        font-weight:bold;
        font-size: $--font-size-medium;
        text-align: right;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display:none;
      }
     }

  }
  @media screen and (max-width: 320px) {
    .register{
      .login-form__title{
        display:none;
      }
    }

    .login-container {
      .logo-box{
        margin: 0;
      }
      .form-container{
        margin: 50px auto;
      }
    }
  }
</style>
